import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Buffer } from 'buffer';
import {enterpriseServiceActions} from '../../store/enterpriseservice-slice'
window.Buffer = Buffer;
class Sidebar extends Component {

  state = {};

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ [menuState]: false });
    } else if (Object.keys(this.state).length === 0) {
      this.setState({ [menuState]: true });
    } else {
      Object.keys(this.state).forEach(i => {
        this.setState({ [i]: false });
      });
      this.setState({ [menuState]: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.querySelector('#sidebar').classList.remove('active');
    Object.keys(this.state).forEach(i => {
      this.setState({ [i]: false });
    });

    const dropdownPaths = [
      { path: '/apps', state: 'appsMenuOpen' },
      { path: '/reports', state: 'reportElementsMenuOpen' },
      { path: '/users', state: 'usersMenuOpen' },
      { path: '/config', state: 'configMenuOpen' },
      { path: '/appli', state: 'appliMenuOpen' },
      { path: '/basic-ui', state: 'basicUiMenuOpen' },
      { path: '/advanced-ui', state: 'advancedUiMenuOpen' },
      { path: '/broadcast', state: 'formElementsMenuOpen' },
      { path: '/tables', state: 'tablesMenuOpen' },
      { path: '/maps', state: 'mapsMenuOpen' },
      { path: '/editors', state: 'editorsMenuOpen' },
      { path: '/icons', state: 'iconsMenuOpen' },
      { path: '/charts', state: 'chartsMenuOpen' },
      { path: '/user-pages', state: 'userPagesMenuOpen' },
      { path: '/error-pages', state: 'errorPagesMenuOpen' },
      { path: '/general-pages', state: 'generalPagesMenuOpen' },
      { path: '/ecommerce', state: 'ecommercePagesMenuOpen' },
    ];

    dropdownPaths.forEach((obj => {
      if (this.isPathActive(obj.path)) {
        this.setState({ [obj.state]: true })
      }
    }));

  }
  encodeUserEmail = (userEmail) => {
    const base64Encoded = Buffer.from(userEmail).toString('base64');
    const replacedChars = base64Encoded
      .replace(/\+/g, '-')
      .replace(/\//g, '_');
    const trimmed = replacedChars.replace(/=+$/, '');

    return trimmed;
  };
  render() {
    console.log(this.props.service.services)
    let roles = ''
    if (this.props.data.user) {
      roles = this.props.data.user
    }
    const second_website_url = 'https://content.acuizen.com';
    const email = "bala.murali@agilitaslearning.com"
    const company = "REC Solar"
    const email_encode = this.encodeUserEmail(email)
    const company_encode = this.encodeUserEmail(company)
    const url = second_website_url + '/sso.php?key=' + email_encode + '&detail=' + company_encode

    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar"  >
        {!this.props.data.loader ?
          <ul className="nav">


            {roles.roles.includes('all') && <>
              <li className={this.isPathActive('/dashboard') ? 'nav-item active' : 'nav-item'}>
                <Link className="nav-link" to="/dashboard">
                  <i className="mdi mdi-home menu-icon"></i>
                  <span className="menu-title">Dashboard</span>
                </Link>
              </li>
              <li className={this.isPathActive('/company') ? 'nav-item active' : 'nav-item'}>
                <Link className="nav-link" to="/company">
                  <i className="mdi mdi-account-multiple-plus menu-icon"></i>
                  <span className="menu-title">Enterprises</span>
                </Link>
              </li>
              <li className={this.isPathActive('/services') ? 'nav-item active' : 'nav-item'}>
                <Link className="nav-link" to="/services">
                  <i className="mdi  mdi-briefcase-check menu-icon"></i>
                  <span className="menu-title">Modules</span>
                </Link>
              </li>
              {/* <li className={this.isPathActive('/hazards') ? 'nav-item active' : 'nav-item'}>
                <Link className="nav-link" to="/hazards">
                  <i className="mdi mdi-alert-outline menu-icon"></i>
                  <span className="menu-title">Hazards</span>
                </Link>
              </li> */}
              <li className={this.isPathActive('/appli') ? 'nav-item active' : 'nav-item'}>
                  <div className={this.state.appliMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('appliMenuOpen')} data-toggle="collapse">
                    <i className="mdi mdi-alert-outline menu-icon" > </i>
                    <span className="menu-title">Repositories</span>
                    <i className="menu-arrow"></i>
                  </div>
                  <Collapse in={this.state.appliMenuOpen}>
                    <ul className="nav flex-column sub-menu">
                     
                    <li className="nav-item" > <Link className={this.isPathActive('/appli/hazards') ? 'nav-link active' : 'nav-link'} to="/appli/hazards">Hazards</Link></li>
                    <li className="nav-item" > <Link className={this.isPathActive('/appli/hazards') ? 'nav-link active' : 'nav-link'} to="/appli/hazards">Icon  Library</Link></li>
                    <li className="nav-item" > <Link className={this.isPathActive('/appli/hazards') ? 'nav-link active' : 'nav-link'} to="/appli/hazards">Email Template</Link></li>
                    </ul>
                  </Collapse>
              </li>

            </>}
          

           
              {roles !== '' && roles.applicationRoles.includes('admin') ? <>

                <li className={this.isPathActive('/dashboard') ? 'nav-item active' : 'nav-item'}>
                  <Link className="nav-link" to="/dashboard">
                    <i className="fa fa-home menu-icon" style={{ fontSize: 16 }}></i>
                    <span className="menu-title">Home</span>
                  </Link>
                </li>

                <li className={this.isPathActive('/appli') ? 'nav-item active' : 'nav-item'}>
                  <div className={this.state.appliMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('appliMenuOpen')} data-toggle="collapse">
                    <i className="fa fa-check-square-o menu-icon" style={{ fontSize: 16 }}> </i>
                    <span className="menu-title">Applications</span>
                    <i className="menu-arrow"></i>
                  </div>
                  <Collapse in={this.state.appliMenuOpen}>
                    <ul className="nav flex-column sub-menu">
                      {this.props.service.services.map(item => {
                        return (
                          <>
                          {item.name.match('Documentation Management')?
                          <li className="nav-item" onClick={()=>this.props.signIn(item.id)}> <Link className={this.isPathActive('/appli/docconfig') ? 'nav-link active' : 'nav-link'} to="/appli/docconfig">{item.name}</Link></li>
                          : item.name.match('Integrated Risk Assessment')? 
                          <li className="nav-item" onClick={()=>this.props.signIn(item.id)}> <Link className={this.isPathActive('/appli/riskconfig') ? 'nav-link active' : 'nav-link'} to="/appli/riskconfig">{item.name}</Link></li>:''
                        }
                          </>
                        )
                      })}

                    </ul>
                  </Collapse>
                </li>


                <li className={this.isPathActive('/config') ? 'nav-item active' : 'nav-item'}>
                  <div className={this.state.configMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('configMenuOpen')} data-toggle="collapse">
                    <i className="fa fa-cogs menu-icon" style={{ fontSize: 16 }}> </i>
                    <span className="menu-title">General Config</span>
                    <i className="menu-arrow"></i>
                  </div>
                  <Collapse in={this.state.configMenuOpen}>
                    <ul className="nav flex-column sub-menu">
                      <li className="nav-item"> <Link className={this.isPathActive('/config/location') ? 'nav-link active' : 'nav-link'} to="/config/location">Locations</Link></li>
                      <li className="nav-item"> <Link className={this.isPathActive('/config/activity') ? 'nav-link active' : 'nav-link'} to="/config/activity">Work Activities</Link></li>
                      {/* <li className="nav-item"> <Link className={this.isPathActive('/users/external') ? 'nav-link active' : 'nav-link'} to="/users/external">Groups</Link></li> */}
                      {/* {this.props.service.services.map(item => {
                        return (

                          <li className="nav-item" onClick={()=>this.props.signIn(item.id)}> <Link className={this.isPathActive('/config/docconfig') ? 'nav-link active' : 'nav-link'} to="/config/docconfig">{item.name}</Link></li>

                        )
                      })} */}
                    </ul>
                  </Collapse>
                </li>
                <li className={this.isPathActive('/users') ? 'nav-item active' : 'nav-item'}>
                  <div className={this.state.usersMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('usersMenuOpen')} data-toggle="collapse">
                    <i className="fa fa-user menu-icon" style={{ fontSize: 16 }}></i>
                    <span className="menu-title">User Management</span>
                    <i className="menu-arrow"></i>
                  </div>
                  <Collapse in={this.state.usersMenuOpen}>
                    <ul className="nav flex-column sub-menu">
                      <li className="nav-item"> <Link className={this.isPathActive('/users/internal') ? 'nav-link active' : 'nav-link'} to="/users/internal">Licensed Users</Link></li>
                      <li className="nav-item"> <Link className={this.isPathActive('/users/external') ? 'nav-link active' : 'nav-link'} to="/users/external">External Users</Link></li>
                      <li className="nav-item"> <Link className={this.isPathActive('/users/others') ? 'nav-link active' : 'nav-link'} to="/users/others">Other Users</Link></li>
                      <li className="nav-item"> <Link className={this.isPathActive('/users/groups') ? 'nav-link active' : 'nav-link'} to="/users/groups">Groups</Link></li>

                    </ul>
                  </Collapse>
                </li>
                <li className={this.isPathActive('/setting') ? 'nav-item active' : 'nav-item'}>
                  <Link className="nav-link" to="/setting">
                    <i className="fa fa-cog menu-icon" style={{ fontSize: 16 }}></i>
                    <span className="menu-title">Settings</span>
                  </Link>
                </li>
                <li className={this.isPathActive('/logout') ? 'nav-item active' : 'nav-item'}>
                  <Link className="nav-link" to="/logout">
                    <i className="fa fa-sign-out menu-icon" style={{ fontSize: 16 }}></i>
                    <span className="menu-title">Logout</span>
                  </Link>
                </li>

              </>  : ''}





          </ul> :''}
      </nav>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    this.onRouteChanged();
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector('body');
    document.querySelectorAll('.sidebar .nav-item').forEach((el) => {

      el.addEventListener('mouseover', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });
  }

}
const mapDispatchToProps = (dispatch) => {
  return {
      signIn: (id) => dispatch(enterpriseServiceActions.setSelectedService(id))
  }
};
const mapStateToProps = state => {
  return {
    data: state.details,
    service: state.service
  }

}
export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Sidebar));
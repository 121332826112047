import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';



const Navbar =()=> {
  const user = useSelector((state) => state.details.user);
  const enterprise = useSelector((state) => state.details.enterprise);
const history = useHistory()
  const toggleOffcanvas=()=> {
    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
  }
  // const toggleRightSidebar=()=> {
  //   document.querySelector('.right-sidebar').classList.toggle('open');
  // }
  const getlogout = () => {
    localStorage.removeItem('access_token');
    history.push('/')
  }

    return (
      <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row" >
        <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center" >
            <button className="navbar-toggler navbar-toggler align-self-center" type="button" onClick={ () => document.body.classList.toggle('sidebar-icon-only') }>
              <span className="icon-menu"></span>
            </button>
            <Link className="navbar-brand brand-logo" to="/"><img src={require("../../assets/images/logo.png") } className="me-2" alt="logo" /></Link>
            <Link className="navbar-brand brand-logo-mini" to="/"><img src={require('../../assets/images/logo-mini.svg')} alt="logo" /></Link>
        </div>
        <div className="navbar-menu-wrapper d-flex align-items-stretch justify-content-end">
          <ul className="navbar-nav">
            <li className="nav-item font-weight-semibold d-none d-lg-block ms-0">
              {(user && user.applicationRoles)  ?
              <h1 className="welcome-text">{user.applicationRoles.includes('all') ?<span className="text-black fw-bold">AcuiZen Super Admin</span>:user.applicationRoles.includes('admin') ? "Super Admin" : user.applicationRoles.includes('entity')? "Admin": user.applicationRoles.includes('curator')? "Curator":''}, {user.applicationRoles.includes('admin') ||user.applicationRoles.includes('entity') ||user.applicationRoles.includes('curator') ?<span className="text-black fw-bold">{user.firstname} {user.lastname}</span> :''}</h1>
             :'' }{/* <h3 className="welcome-sub-text">Your performance summary this week </h3> */}
            </li>
          </ul>
          <ul className="navbar-nav navbar-nav-right">
           {enterprise ?
          <li>
        <img
          // @ts-ignore
          src={"https://mpower-s3.s3-ap-southeast-1.amazonaws.com/company_logos/"+enterprise.logo} className="me-2" alt="logo" style={{height:80}}/>
          </li>:''}
            {/* <li className="nav-item me-2 d-none d-lg-flex">
              <Dropdown>
                <Dropdown.Toggle className="nav-link">
                  <Form.Control type="text" className="dropdown-bordered" id="exampleInputUsername1" placeholder="Select category" />
                </Dropdown.Toggle>
                <Dropdown.Menu className="preview-list navbar-dropdown">
                  <p className="p-3 mb-0 text-black">Messages</p>
                  <Dropdown.Item className="dropdown-item preview-item" onClick={evt =>evt.preventDefault()}>
                    <div className="preview-thumbnail">
                      <img src={require("../../assets/images/faces/face4.jpg")} alt="user" className="profile-pic"/>
                    </div>
                    <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                      <h6 className="preview-subject ellipsis mb-1 font-weight-normal">David Grey</h6>
                      <p className="text-gray mb-0">
                         The meeting is cancelled
                      </p>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item className="dropdown-item preview-item" onClick={evt =>evt.preventDefault()}>
                    <div className="preview-thumbnail">
                      <img src={require("../../assets/images/faces/face2.jpg")} alt="user" className="profile-pic"/>
                    </div>
                    <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                      <h6 className="preview-subject ellipsis mb-1 font-weight-normal">Tim Cook</h6>
                      <p className="text-gray mb-0">
                          New product launch
                      </p>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item className="dropdown-item preview-item" onClick={evt =>evt.preventDefault()}>
                    <div className="preview-thumbnail">
                      <img src={require("../../assets/images/faces/face3.jpg")} alt="user" className="profile-pic"/>
                    </div>
                    <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                      <h6 className="preview-subject ellipsis mb-1 font-weight-normal">Johnson</h6>
                      <p className="text-gray mb-0">
                        Upcoming board meeting 
                      </p>
                    </div>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li> */}
            {/* <li className="navbar-date-picker  d-none d-lg-flex">
              <DefaultDatepicker />
            </li> */}
            {/* <li className="nav-item">
              <form className="search-form" action="#">
                <i className="icon-search"></i>
                <input type="search" className="form-control" placeholder="Search Here" title="Search here" />
              </form>
            </li> */}
            {/* <li className="nav-item me-1 ms-0">
              <Dropdown>
                <Dropdown.Toggle className="nav-link count-indicator">
                  <i className="icon-mail icon-lg"></i>
                </Dropdown.Toggle>
                <Dropdown.Menu className="preview-list navbar-dropdown">
                  <p className="p-3 mb-0 text-black">Messages</p>
                  <Dropdown.Item className="dropdown-item preview-item" onClick={evt =>evt.preventDefault()}>
                    <div className="preview-thumbnail">
                      <img src={require("../../assets/images/faces/face4.jpg")} alt="user" className="profile-pic"/>
                    </div>
                    <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                      <h6 className="preview-subject ellipsis mb-1 font-weight-normal">David Grey</h6>
                      <p className="text-gray mb-0">
                         The meeting is cancelled
                      </p>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item className="dropdown-item preview-item" onClick={evt =>evt.preventDefault()}>
                    <div className="preview-thumbnail">
                      <img src={require("../../assets/images/faces/face2.jpg")} alt="user" className="profile-pic"/>
                    </div>
                    <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                      <h6 className="preview-subject ellipsis mb-1 font-weight-normal">Tim Cook</h6>
                      <p className="text-gray mb-0">
                          New product launch
                      </p>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item className="dropdown-item preview-item" onClick={evt =>evt.preventDefault()}>
                    <div className="preview-thumbnail">
                      <img src={require("../../assets/images/faces/face3.jpg")} alt="user" className="profile-pic"/>
                    </div>
                    <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                      <h6 className="preview-subject ellipsis mb-1 font-weight-normal">Johnson</h6>
                      <p className="text-gray mb-0">
                        Upcoming board meeting 
                      </p>
                    </div>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li> */}
            {/* <li className="nav-item">
              <Dropdown>
                <Dropdown.Toggle className="nav-link count-indicator">
                  <i className="icon-bell"></i>
                  <span className="count"></span>
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu navbar-dropdown preview-list">
                  <p className="p-3 mb-0 text-black">Notifications</p>
                  <Dropdown.Item className="dropdown-item preview-item" onClick={evt =>evt.preventDefault()}>
                    <div className="preview-thumbnail">
                      <div className="preview-icon bg-success">
                        <i className="ti-info-alt"></i>
                      </div>
                    </div>
                    <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                      <h6 className="preview-subject font-weight-normal mb-1">Application Error</h6>
                      <p className="text-gray ellipsis mb-0">
                      Just now
                      </p>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item className="dropdown-item preview-item" onClick={evt =>evt.preventDefault()}>
                    <div className="preview-thumbnail">
                      <div className="preview-icon bg-warning">
                        <i className="ti-settings"></i>
                      </div>
                    </div>
                    <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                      <h6 className="preview-subject font-weight-normal mb-1">Settings</h6>
                      <p className="text-gray ellipsis mb-0">
                      Private message
                      </p>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item className="dropdown-item preview-item" onClick={evt =>evt.preventDefault()}>
                    <div className="preview-thumbnail">
                      <div className="preview-icon bg-info">
                        <i className="ti-user"></i>
                      </div>
                    </div>
                    <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                      <h6 className="preview-subject font-weight-normal mb-1">New user registration</h6>
                      <p className="text-gray ellipsis mb-0">
                      2 days ago!
                      </p>
                    </div>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li> */}
            <li className="nav-item nav-profile">

            <i class="fa fa-cog fa-2x" aria-hidden="true" style={{paddingRight:20}} onClick={()=>history.push('/setting')}></i>
            <i class="fa fa-sign-out fa-2x" aria-hidden="true" onClick={()=>getlogout()}></i>
            </li>
          </ul>
          <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" onClick={()=>toggleOffcanvas()}>
            <span className="icon-menu"></span>
          </button>
        </div>
      </nav>
    );
  }


export default Navbar;

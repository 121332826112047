import React, { Component, Suspense, lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Spinner from "../app/shared/Spinner";

const Dashboard = lazy(() => import("./dashboard/Dashboard"));

const Widgets = lazy(() => import("./widgets/Widgets"));

const KanbanBoard = lazy(() => import("./apps/KanbanBoard"));
const Chats = lazy(() => import("./apps/Chats"));
const TodoList = lazy(() => import("./apps/TodoList"));

const Accordions = lazy(() => import("./basic-ui/Accordions"));
const Buttons = lazy(() => import("./basic-ui/Buttons"));
const Badges = lazy(() => import("./basic-ui/Badges"));
const Breadcrumbs = lazy(() => import("./basic-ui/Breadcrumbs"));
const Dropdowns = lazy(() => import("./basic-ui/Dropdowns"));
const Modals = lazy(() => import("./basic-ui/Modals"));
const Progress = lazy(() => import("./basic-ui/Progress"));
const Paginations = lazy(() => import("./basic-ui/Paginations"));
const TabsPage = lazy(() => import("./basic-ui/TabsPage"));
const Typography = lazy(() => import("./basic-ui/Typography"));
const Popups = lazy(() => import("./basic-ui/Popups"));

const Dragula = lazy(() => import("./advanced-ui/Dragula"));
const Clipboard = lazy(() => import("./advanced-ui/Clipboards"));
const ContextMenu = lazy(() => import("./advanced-ui/ContextMenus"));
const Sliders = lazy(() => import("./advanced-ui/Sliders"));
const Carousel = lazy(() => import("./advanced-ui/Carousel"));
const Loaders = lazy(() => import("./advanced-ui/Loaders"));
const TreeView = lazy(() => import("./advanced-ui/TreeView"));

const BasicElements = lazy(() => import("./form-elements/BasicElements"));
const AdvancedElements = lazy(() => import("./form-elements/AdvancedElements"));
const Wizard = lazy(() => import("./form-elements/Wizard"));

const BasicTable = lazy(() => import("./tables/BasicTable"));
const ReactTable = lazy(() => import("./tables/ReactTable"));
const SortableTable = lazy(() => import("./tables/SortableTable"));

const VectorMap = lazy(() => import("./maps/VectorMap"));
const SimpleMap = lazy(() => import("./maps/SimpleMap"));

const Notifications = lazy(() => import("./notifications/Notifications"));

const Mdi = lazy(() => import("./icons/Mdi"));
const FlagIcons = lazy(() => import("./icons/FlagIcons"));
const FontAwesome = lazy(() => import("./icons/FontAwesome"));
const SimpleLine = lazy(() => import("./icons/SimpleLine"));
const Themify = lazy(() => import("./icons/Themify"));
const TypIcons = lazy(() => import("./icons/TypIcons"));

const TextEditors = lazy(() => import("./editors/TextEditors"));
const CodeEditor = lazy(() => import("./editors/CodeEditor"));

const ChartJs = lazy(() => import("./charts/ChartJs"));
const C3Charts = lazy(() => import("./charts/C3Charts"));
const Chartist = lazy(() => import("./charts/Chartist"));
const GoogleCharts = lazy(() => import("./charts/GoogleCharts"));
const SparkLineCharts = lazy(() => import("./charts/SparkLineCharts"));
const GuageChart = lazy(() => import("./charts/GuageChart"));

const Error404 = lazy(() => import("./error-pages/Error404"));
const Error500 = lazy(() => import("./error-pages/Error500"));

const Login = lazy(() => import("./user-pages/Login"));
const Login2 = lazy(() => import("./user-pages/Login2"));
const Register1 = lazy(() => import("./user-pages/Register"));
const Register2 = lazy(() => import("./user-pages/Register2"));
const Lockscreen = lazy(() => import("./user-pages/Lockscreen"));

const BlankPage = lazy(() => import("./general-pages/BlankPage"));
const Profile = lazy(() => import("./general-pages/Profile"));
const Faq = lazy(() => import("./general-pages/Faq"));
const Faq2 = lazy(() => import("./general-pages/Faq2"));
const NewsGrid = lazy(() => import("./general-pages/NewsGrid"));
const Timeline = lazy(() => import("./general-pages/Timeline"));
const SearchResults = lazy(() => import("./general-pages/SearchResults"));
const Portfolio = lazy(() => import("./general-pages/Portfolio"));

const Invoice = lazy(() => import("./ecommerce/Invoice"));
const Pricing = lazy(() => import("./ecommerce/Pricing"));
const ProductCatalogue = lazy(() => import("./ecommerce/ProductCatalogue"));
const ProjectList = lazy(() => import("./ecommerce/ProjectList"));

const Email = lazy(() => import("./apps/Email"));
const Calendar = lazy(() => import("./apps/Calendar"));
const Gallery = lazy(() => import("./apps/Gallery"));
const Knowledge = lazy(() => import("./pages/Knowledge"));
const CuratorKnowledge = lazy(() => import("./pages/CuratorKnowledge"));
const Checklist = lazy(() => import("./pages/Checklist"));
const Document = lazy(() => import("./pages/Document"));
const Forms = lazy(() => import("./pages/Forms"));
const EndUser = lazy(() => import("./users/EndUser"));
const EntityUser = lazy(() => import("./users/EntityUser"));
const Curator = lazy(() => import("./users/Curator"));
const GroupAssign = lazy(() => import("./assignment/GroupAssign"));
const EditChecklist = lazy(() => import("./pages/EditChecklist"));
const EditForm = lazy(() => import("./pages/EditForm"));
const EditCurate = lazy(() => import("./pages/Curate"));
const CurateByStep = lazy(() => import("./pages/CurateByStep"));
const Groups = lazy(() => import("./group/Groups"));

const KnowledgeAssign = lazy(() => import("./assignment/KnowledgeAssign"));
const ChecklistAssign = lazy(() => import("./assignment/ChecklistAssign"));
const DocumentAssign = lazy(() => import("./assignment/DocumentAssign"));
const FormAssign = lazy(() => import("./assignment/FormAssign"));
const CuratorAssign = lazy(() => import("./assignment/CuratorAssign"));
const EditUser = lazy(() => import("./users/EditUser"));
const Curation = lazy(() => import("./curation/Curation"));
const Twoway = lazy(() => import("./broadcast/Twoway"));
const Oneway = lazy(() => import("./broadcast/Oneway"));
const Template = lazy(() => import("./broadcast/Template"));
const List_Broadcast = lazy(() => import("./broadcast/List"));
const CFCurations = lazy(() => import("./curation/CFCuration"));
const KnowledgeReport = lazy(() => import("./reports/Knowledge"));
const ChecklistReport = lazy(() => import("./reports/Checklists"));
const FormReport = lazy(() => import("./reports/Forms"));
const DocumentReport = lazy(() => import('./reports/Documents'))
const PDF = lazy(() => import('./widgets/PDF'))
const Setting = lazy(() => import('./pages/Setting'))
const ForgetPassword = lazy(() => import('./user-pages/ForgetPassword'))
const CompanyDashboard = lazy(() => import('./company/Dashboard'))
const Company = lazy(() => import('./company/Company'))
const Services = lazy(() => import('./company/Services'))
const ServiceDashboard = lazy(() => import('./dashboard/Services'))
const Config = lazy(() => import('./Config/Config'))
const Internal = lazy(() => import('./users/Internal'))
const External = lazy(() => import('./users/External'))
const RiskDash = lazy(() => import('./ra/RiskAssessment'))
const Routine = lazy(() => import('./ra/Routine'))
const NonRoutine = lazy(() => import('./ra/NonRoutine'))
const HazardBased = lazy(() => import('./ra/HazardBased'))
const HazardEdit = lazy(() => import('./ra/HazardAmend'))
const EditRa = lazy(() => import('./ra/AmendRisk'))
const HazardView = lazy(() => import('./ra/HazardView'))
const RaView = lazy(() => import('./ra/ViewRisk'))
const RaConfig = lazy(() => import('./ra/Config'))
const RISKConfig =lazy(()=>import('./risk/Dashboard'))
const WorkActivity =lazy(()=>import('./Config/WorkActivity'))
const DocConfig =lazy(()=>import('./document/Dashboard'))
const HAZARDS =lazy(()=>import('./company/Hazards'))
// const AddCompany =lazy(()=>import('./company/AddCompany'))
const AppRoutes = (props) => {

  const isLogin = localStorage.getItem("access_token") ? true : false;
  console.log(props);

  var data = "";
  var applicationRole = '';
  if (props.user) {
    data = props.user.roles;
    applicationRole = props.user.applicationRoles;
  }

  return (
    <Suspense fallback={<Spinner />}>
      <Switch>

        <Route exact path="/" component={Login} />
        <Route exact path="/forgetpassword" component={ForgetPassword} />
        {props.data ? <>
          {isLogin ? <>

            {data.includes('all') ? <>
              <Route exact path="/dashboard" component={CompanyDashboard} />
              <Route exact path="/company" component={Company} />
              {/* <Route exact path="/addcompany" component={AddCompany} /> */}
              <Route exact path="/services" component={Services} />
              <Route exact path="/appli/hazards" component={HAZARDS} />
            </> : <>

              <Route exact path="/dashboard" component={ServiceDashboard} />
              <Route exact path="/dashboard_kn" component={Dashboard} />
              <Route exact path="/widgets" component={Widgets} />
              <Route exact path="/apps/knowledge" component={Knowledge} />
              <Route exact path="/config/location" component={Config} />
              <Route exact path="/config/activity" component={WorkActivity} />
              <Route exact path="/appli/docconfig" component={DocConfig} />
              <Route
                exact
                path="/apps/curatorknowledge"
                component={CuratorKnowledge}
              />
              <Route exact path="/apps/checklist" component={Checklist} />
              <Route
                exact
                path="/apps/checklist/edit/:id"
                component={EditChecklist}
              />
              <Route
                exact
                path="/apps/knowledge/edit"
                component={CurateByStep}
              />
              <Route exact path="/apps/knowledge/curate" component={Curation} />
              <Route
                exact
                path="/apps/checklist/curate"
                component={CFCurations}
              />
              <Route exact path="/apps/forms/curate" component={CFCurations} />
              <Route exact path="/apps/form/edit/:id" component={EditForm} />
              <Route exact path="/dashboard_doc" component={Document} />
              <Route exact path="/apps/forms" component={Forms} />
              <Route exact path="/users/enduser" component={EndUser} />
              <Route
                exact
                path="/users/enduser/edit/:id"
                component={EditUser}
              />
              <Route
                exact
                path="/reports/knowledge"
                component={KnowledgeReport}
              />
              <Route
                exact
                path="/reports/checklists"
                component={ChecklistReport}
              />
              <Route
                exact
                path="/reports/forms"
                component={FormReport}
              />
              <Route
                exact
                path="/reports/documents"
                component={DocumentReport}
              />
              <Route
                exact
                path="/setting"
                component={Setting}
              />
              <Route
                exact
                path="/users/internal"
                component={Internal}
              />
              <Route
                exact
                path="/users/external"
                component={External}
              />
              <Route exact path="/users/entity" component={EntityUser} />
              <Route exact path="/users/curator" component={Curator} />
              <Route exact path="/users/groups" component={Groups} />
              <Route exact path="/appli/riskconfig" component={RISKConfig} />
              <Route path="/basic-ui/curator" component={CuratorAssign} />
              <Route path="/basic-ui/knowledge" component={KnowledgeAssign} />
              <Route path="/basic-ui/checklist" component={ChecklistAssign} />
              <Route path="/basic-ui/document" component={DocumentAssign} />
              <Route path="/basic-ui/form" component={FormAssign} />
              <Route path="/basic-ui/group" component={GroupAssign} />
              <Route exact path="/" component={Login} />
              <Route path="/broadcast/one-way" component={Oneway} />
              <Route path="/broadcast/two-way" component={Twoway} />
              <Route path="/broadcast/template" component={Template} />
              <Route path="/broadcast/list" component={List_Broadcast} />
              <Route exact path="/users/edit_user" component={EditUser} />
              <Route exact path="/curation" component={Curation} />
              <Route exact path="/pdf" component={PDF} />
              <Route exact path="/dashboard_ra" component={RiskDash} />

              <Route exact path="/routine">
                <Routine />
              </Route>
              <Route exact path="/nonroutine">
                <NonRoutine />
              </Route>
              <Route exact path="/hazardbased">
                <HazardBased />
              </Route>
              <Route exact path="/amendhazard">
                <HazardEdit />
              </Route>
              <Route exact path="/amendrisk">
                <EditRa />
              </Route>
              <Route exact path="/viewhazard">
                <HazardView />
              </Route>
              <Route exact path="/viewrisk">
                <RaView />
              </Route>


            </>}</> : <Redirect to="/" />}
        </> : ''}

      </Switch>
    </Suspense>
  );

}

export default AppRoutes;
